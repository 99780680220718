import { PunkDetail } from "@containers";
import { Router } from "@reach/router";
import React from "react";

const PunksPage = () => (
    <Router>
        <PunkDetail path="/punks/:punkId" />
    </Router>
);

export default PunksPage;
